import React, { useState } from 'react';
import { Form, Button, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';

const UploadDocument = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        aadhar: null,
        communityCertificate: null,
        jaathagam: null
    });

    const [errors, setErrors] = useState({
        name: '',
        email: '',
        aadhar: '',
        communityCertificate: '',
        jaathagam: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleFileChange = (e) => {
        const { name, files: selectedFiles } = e.target;
        const file = selectedFiles[0];
        const sizeLimit = 5 * 1024 * 1024; // 5 MB in bytes

        let error = '';

        if (file) {
            if (file.size > sizeLimit) {
                error = 'File size must be less than 5MB';
            } else if (file.type !== 'application/pdf') {
                error = 'File must be a PDF';
            }
        }

        setErrors({
            ...errors,
            [name]: error
        });

        if (!error) {
            setFormData({
                ...formData,
                [name]: file
            });
        } else {
            setFormData({
                ...formData,
                [name]: null
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        let allFieldsValid = true;
        let newErrors = {};

        if (!formData.name) {
            newErrors.name = 'Name is required';
            allFieldsValid = false;
        }

        if (!formData.email) {
            newErrors.email = 'Email is required';
            allFieldsValid = false;
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = 'Email is invalid';
            allFieldsValid = false;
        }

        for (const key of ['aadhar', 'communityCertificate', 'jaathagam']) {
            if (!formData[key]) {
                newErrors[key] = 'This field is required';
                allFieldsValid = false;
            }
        }

        if (!allFieldsValid) {
            setErrors(newErrors);
            return;
        }

        const uploadData = new FormData();
        uploadData.append('name', formData.name);
        uploadData.append('email', formData.email);
        uploadData.append('aadhar', formData.aadhar);
        uploadData.append('communityCertificate', formData.communityCertificate);
        uploadData.append('jaathagam', formData.jaathagam);

        try {
            // const response = await axios.post('https://cnnmandram.com/cnnmandram/upload_document.php', uploadData, {
            const response = await axios.post('https://cnnmandram.com/cnnmandram/upload_document.php', uploadData, {
                // const response = await axios.post('https://localhost/cnnmandram/upload_document.php', uploadData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            console.log('Server response:', response.data);

            if (response.data.status === 'success') {
                alert('Documents uploaded successfully');
                window.location.href = '/';
            } else {
                console.error('Server error response:', response.data);
                alert('There was an error uploading the documents: ' + response.data.message);
            }
        } catch (error) {
            console.error('Error uploading files:', error);
            alert('There was an error uploading the documents: ' + error.message);
        }
    };


    return (
        <Container className="mainSecCont">
            <div className="row d-flex justify-content-center">
                <div className="col-lg-8">
                    <h1>Upload Documents</h1>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleInputChange}
                                isInvalid={!!errors.name}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.name}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                isInvalid={!!errors.email}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.email}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Aadhar</Form.Label>
                            <Form.Control
                                type="file"
                                name="aadhar"
                                onChange={handleFileChange}
                                isInvalid={!!errors.aadhar}
                                accept="application/pdf"
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.aadhar}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Community Certificate</Form.Label>
                            <Form.Control
                                type="file"
                                name="communityCertificate"
                                onChange={handleFileChange}
                                isInvalid={!!errors.communityCertificate}
                                accept="application/pdf"
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.communityCertificate}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Jaathagam</Form.Label>
                            <Form.Control
                                type="file"
                                name="jaathagam"
                                onChange={handleFileChange}
                                isInvalid={!!errors.jaathagam}
                                accept="application/pdf"
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.jaathagam}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Button type="submit" variant="dark">Upload</Button>
                    </Form>
                </div>
            </div>
        </Container>
    );
};

export default UploadDocument;
