
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import About from './components/About';
import Faq from './components/Faq';
import Blog from './components/Blog';
import Contact from './components/Contact';
import BlogDetail from './components/BlogDetail';
import ProfileDetails from './components/ProfileDetails';
import Wedding from './components/Wedding';
import AllProfile from './components/AllProfile';
import CommingSoon from './components/CommingSoon';
import UserPlan from './components/UserPlan';
import MakeReservation from './components/MakeReservation';
import PageNotFound from './components/PageNotFound';
import RegistrationForm from './components/RegistrationForm.jsx'
import PhotoGallery_1 from './components/PhotoGallery_1';
import PhotoGallery from './components/PhotoGallery';
import Plans from './components/Plans';
import SignUp from './components/SignUp';
import UserProfile from './components/UserProfile';
import UserInterests from './components/UserInterests';
import UserDashBoard from './components/UserDashBoard';
import Services from './components/Services';
import Enquiry from './components/Enquiry';
import UserChat from './components/UserChat';
import WeddingVideo from './components/WeddingVideo';
import UserSetting from './components/UserSetting';
import UserProfileEdit from './components/UserProfileEdit';
import History from './components/History.jsx'
import Memories from './components/Memories.jsx'
import Motives from './components/Motives.jsx'
import UploadDocument from './components/UploadDocument.jsx';
import UserDetails from './components/UserDetails.jsx'
import OtpVerification from './components/OtpVerification.jsx'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import 'slick-carousel/slick/slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../node_modules/popper.js/dist/popper.js';
import '../node_modules/bootstrap/dist/css/bootstrap.css';
import '../node_modules/bootstrap/dist/js/bootstrap.js';
import '../node_modules/jquery/dist/jquery.js'
import 'select2';
import 'slick-carousel';
import RegisterFormMain from './components/RegisterFormMain'
// import SmsRegistrationForm from './components/SmsRegistrationForm';
import Payment from './components/Payment.jsx';
import RegisteredUsersList from './components/RegisteredUsersList.jsx';
function App() {
  return (

    <Router basename='/'>
      <Header />
      <Routes >
        <Route path='/' element={<Home />} />
        <Route path='/UploadDocument' element={<UploadDocument />} />
        <Route path='/Payment' element={<Payment />} />
        {/* <Route path='/SmsRegistrationForm' element={<SmsRegistrationForm />} /> */}
        <Route path='/RegistrationForm' element={<RegistrationForm />} />
        <Route path='/UserDetails' element={<UserDetails />} />


        <Route path='/RegisteredUsersList' element={<RegisteredUsersList />} />
        <Route path='/SignUp' element={<RegisterFormMain />} />


        <Route path='/about' element={<About />} />
        <Route path='/Faq' element={<Faq />} />
        <Route path='/Blog' element={<Blog />} />
        <Route path='/Contact' element={<Contact />} />
        <Route path='/Motives' element={<Motives />} />
        <Route path='/History' element={<History />} />
        <Route path='/Memories' element={<Memories />} />
        <Route path='/BlogDetail' element={<BlogDetail />} />
        {/* <Route path='/ProfileDetail' element={<ProfileDetail />} /> */}
        <Route path='/ProfileDetails' element={<ProfileDetails />} />
        <Route path='/Wedding' element={<Wedding />} />
        <Route path='/WeddingVideo' element={<WeddingVideo />} />
        <Route path='/AllProfile' element={<AllProfile />} />
        <Route path='/CommingSoon' element={<CommingSoon />} />
        <Route path='/OtpVerification' element={<OtpVerification />} />
        {/* <Route path='/Login' element={<Login />} /> */}
        {/* <Route path="/userAccount" element={<UserAccount />} /> */}
        <Route path='/UserPlan' element={<UserPlan />} />
        <Route path='/MakeReservation' element={<MakeReservation />} />
        <Route path='/PageNotFound' element={<PageNotFound />} />
        <Route path='/PhotoGallery_1' element={<PhotoGallery_1 />} />
        <Route path='/PhotoGallery' element={<PhotoGallery />} />
        <Route path='/Plans' element={<Plans />} />
        {/* <Route path='/SignUp' element={<SignUp />} /> */}
        <Route path='/UserProfile' element={<UserProfile />} />
        <Route path='/UserInterests' element={<UserInterests />} />
        <Route path='/UserDashBoard' element={<UserDashBoard />} />
        <Route path='/UserChat' element={<UserChat />} />
        <Route path='/UserSetting' element={<UserSetting />} />
        <Route path='/Services' element={<Services />} />
        <Route path='/Enquiry' element={<Enquiry />} />
        <Route path='/UserProfileEdit' element={<UserProfileEdit />} />
      </Routes>
      <Footer />
    </Router >



  );
}

export default App;