
import { Link } from 'react-router-dom'
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

const AllProfile = () => {

  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filterGender, setFilterGender] = useState('');
  const [filterAgeRange, setFilterAgeRange] = useState('');
  const [filterDistrict, setFilterDistrict] = useState('');

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [filterGender, filterAgeRange, filterDistrict, users]);

  const fetchUsers = async () => {
    try {
      // const response = await axios.get('http://localhost/cnnmandram/fetchRegisteredUsers.php');
      const response = await axios.get('https://cnnmandram.com/cnnmandram/fetchRegisteredUsers.php');
      setUsers(response.data);
      setFilteredUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };


  const applyFilters = () => {
    let filteredData = users;

    if (filterGender !== '') {
      filteredData = filteredData.filter(user => user.gender === filterGender);
    }

    // Function to calculate age from date of birth
    const calculateAge = (dateOfBirth) => {
      const today = new Date();
      const birthDate = new Date(dateOfBirth);
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDiff = today.getMonth() - birthDate.getMonth();

      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }

      return age;
    };

    // Inside applyFilters function
    if (filterAgeRange !== '') {
      const [minAge, maxAge] = filterAgeRange.split('-').map(Number);

      filteredData = filteredData.filter(user => {
        const userAge = calculateAge(user.date_of_birth); // Replace with your actual age calculation logic
        return userAge >= minAge && userAge <= maxAge;
      });
    }
    if (filterDistrict !== '') {
      filteredData = filteredData.filter(user => user.living_place === filterDistrict);
    }

    setFilteredUsers(filteredData);
  };

  const handleGenderChange = (e) => {
    setFilterGender(e.target.value);
  };





  const handleAgeRangeChange = (e) => {
    setFilterAgeRange(e.target.value);
  };

  const handleDistrictChange = (e) => {
    setFilterDistrict(e.target.value);
  };




  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 4;

  // const totalPages = Math.ceil(userDetails.length / itemsPerPage);
  const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);

  // const handleClickNext = () => {
  //   if (currentPage < totalPages) {
  //     setCurrentPage(currentPage + 1);
  //   }
  // };

  // const handleClickPrev = () => {
  //   if (currentPage > 1) {
  //     setCurrentPage(currentPage - 1);
  //   }
  // };
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredUsers.slice(indexOfFirstItem, indexOfLastItem);
  const handleClickNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleClickPrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // const indexOfLastItem = currentPage * itemsPerPage;
  // const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  // const currentItems = userDetails.slice(indexOfFirstItem, indexOfLastItem);
  return (
    <>

      {/* SUB-HEADING */}
      <section>
        <div className="all-pro-head">
          <div className="container">
            <div className="row">
              <h1>Lakhs of Happy Marriages</h1>
              <a href="sign-up.html">
                Join now for Free{" "}
                <i className="fa fa-handshake-o" aria-hidden="true" />
              </a>
            </div>
          </div>
        </div>

      </section>
      <section>
        <div className="all-weddpro all-jobs all-serexp chosenini">
          <div className="container">
            <div className="row">
              <div className="col-md-3 fil-mob-view">
                <span className="filter-clo">+</span>

                <div className="filt-com lhs-cate">
                  <h4>
                    <i className="fa fa-search" aria-hidden="true" /> I'm looking
                    for
                  </h4>
                  <div className="form-group">

                    <select
                      id="genderFilter" className="chosen-select form-control"
                      value={filterGender}
                      onChange={handleGenderChange}
                    >
                      <option value="">-- All --</option>
                      <option value="M">Male</option>
                      <option value="F">Female</option>
                    </select>

                  </div>
                </div>

                <div className="filt-com lhs-cate">
                  <h4>
                    <i className="fa fa-clock-o" aria-hidden="true" />
                    Age
                  </h4>
                  <div className="form-group">


                    <select
                      id="ageRangeFilter" className="chosen-select form-control"
                      value={filterAgeRange}
                      onChange={handleAgeRangeChange}
                    >
                      <option value="">-- All --</option>
                      <option value="18-30">18 to 30</option>
                      <option value="31-40">31 to 40</option>

                    </select>


                  </div>
                </div>



                <div className="filt-com lhs-cate">
                  <h4>
                    <i className="fa fa-map-marker" aria-hidden="true" />
                    Location
                  </h4>
                  <div className="form-group">

                    <select
                      id="livingPlaceFilter"
                      className="form-control chosen-select" name="addjbmaincate"
                      value={filterDistrict}
                      onChange={handleDistrictChange}
                    >
                      <option value="">-- All --</option>
                      <option value="Ariyalur">Ariyalur</option>
                      <option value="Chengalpattu">Chengalpattu</option>
                      <option value="Chennai">Chennai</option>
                      <option value="Coimbatore">Coimbatore</option>

                    </select>


                  </div>
                </div>


                {/* <div className="filt-com filt-send-query">
                  <div className="send-query">
                    <h5>What are you looking for?</h5>
                    <p>We will help you to arrage the best match to you.</p>
                    <a href="#!" data-toggle="modal" data-target="#expfrm">
                      Send your queries
                    </a>
                  </div>
                </div> */}

              </div>
              <div className="col-md-9">
                <div className="short-all">
                  <div className="short-lhs">
                    Showing <b>{filteredUsers.length}</b> profiles
                  </div>
                  <div className="short-rhs">
                    <ul>
                      <li>Sort by:</li>
                      <li>
                        <div className="form-group">
                          <select className="chosen-select">
                            <option value="">Most relative</option>
                            <option value="Men">Date listed: Newest</option>
                            <option value="Men">Date listed: Oldest</option>
                          </select>
                        </div>
                      </li>

                    </ul>
                  </div>
                </div>
                <div className="all-list-sh">
                  <ul>



                    {currentItems.map((user) => (

                      <li key={user.id}>
                        <div
                          className="all-pro-box user-avil-onli"
                          data-useravil="avilyes"
                          data-aviltxt="Available online"
                        >

                          <div className="pro-img">
                            <img src="https://img.freepik.com/premium-photo/kerala-s-serene-beauty-reflected-by-girl-traditional-attire-radiant-smile_878783-12001.jpg" alt="" />

                            <div className="pro-ave" title="User currently available">
                              <span className="pro-ave-yes" />
                            </div>
                            <div className="pro-avl-status">
                              <h5>Available Online</h5>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col">


                              <div className="py-2">
                                <p className='mb-1'>
                                  <b>{user.fullname} </b>
                                </p>
                                <p className='mb-1'>
                                  <b>{user.birth_place} </b>
                                </p>


                                <small>{user.job_type}</small>
                                <br />
                                <small>Date of Birth : {user.dob}</small>
                                <br />
                                <small>Height: 155Cms</small>

                              </div>
                            </div>
                          </div>
                          <span
                            className="enq-sav"
                            data-toggle="tooltip"
                            title="Click to save this provile."
                          >
                            <i className="fa fa-thumbs-o-up" aria-hidden="true" />
                          </span>
                        </div>
                      </li>
                    ))}

                  </ul>

                </div>
              </div>
            </div>
            <div className="pagination d-flex justify-content-between align-items-center">
              <button onClick={handleClickPrev} disabled={currentPage === 1}>
                Previous
              </button>
              <p className="mb-0 px-3">{currentPage} of {totalPages}</p>
              <button onClick={handleClickNext} disabled={currentPage === totalPages}>
                Next
              </button>
            </div>

          </div>

        </div>
      </section>
      <div className="modal fade" id="sendInter">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">

            <div className="modal-header">
              <h4 className="modal-title seninter-tit">
                Send interest to <span className="intename2">Jolia</span>
              </h4>
              <button type="button" className="close" data-bs-dismiss="modal">
                ×
              </button>
            </div>

            <div className="modal-body seninter">
              <div className="lhs">
                <img src="https://img.freepik.com/premium-photo/kerala-s-serene-beauty-reflected-by-girl-traditional-attire-radiant-smile_878783-12001.jpg" alt="" className="intephoto2" />
              </div>
              <div className="rhs">
                <h4>
                  Permissions: <span className="intename2">Jolia</span> Can able to
                  view the below details
                </h4>
                <ul>
                  <li>
                    <div className="chbox">
                      <input type="checkbox" id="pro_about" defaultChecked="" />
                      <label htmlFor="pro_about">About section</label>
                    </div>
                  </li>
                  <li>
                    <div className="chbox">
                      <input type="checkbox" id="pro_photo" />
                      <label htmlFor="pro_photo">Photo gallery</label>
                    </div>
                  </li>
                  <li>
                    <div className="chbox">
                      <input type="checkbox" id="pro_contact" />
                      <label htmlFor="pro_contact">Contact info</label>
                    </div>
                  </li>
                  <li>
                    <div className="chbox">
                      <input type="checkbox" id="pro_person" />
                      <label htmlFor="pro_person">Personal info</label>
                    </div>
                  </li>
                  <li>
                    <div className="chbox">
                      <input type="checkbox" id="pro_hobbi" />
                      <label htmlFor="pro_hobbi">Hobbies</label>
                    </div>
                  </li>
                  <li>
                    <div className="chbox">
                      <input type="checkbox" id="pro_social" />
                      <label htmlFor="pro_social">Social media</label>
                    </div>
                  </li>
                </ul>
                <div className="form-floating">
                  <textarea
                    className="form-control"
                    id="comment"
                    name="text"
                    placeholder="Comment goes here"
                    defaultValue={""}
                  />
                  <label htmlFor="comment">
                    Write some message to <span className="intename" />
                  </label>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button type="button" className="btn btn-primary">
                Send interest
              </button>
              <button
                type="button"
                className="btn btn-outline-danger"
                data-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="chatbox">
        <span className="comm-msg-pop-clo">
          <i className="fa fa-times" aria-hidden="true" />
        </span>
        <div className="inn">
          <form name="new_chat_form" method="post">
            <div className="s1">
              <img src="https://img.freepik.com/premium-photo/kerala-s-serene-beauty-reflected-by-girl-traditional-attire-radiant-smile_878783-12001.jpg" className="intephoto2" alt="" />
              <h4>
                <b className="intename2">Julia</b>,
              </h4>
              <span className="avlsta avilyes">Available online</span>
            </div>
            <div className="s2 chat-box-messages">
              <span className="chat-wel">Start a new chat!!! now</span>
              <div className="chat-con">
                <div className="chat-lhs">Hi</div>
                <div className="chat-rhs">Hi</div>
              </div>

            </div>
            <div className="s3">
              <input
                type="text"
                name="chat_message"
                placeholder="Type a message here.."
                required=""
              />
              <button id="chat_send1" name="chat_send" type="submit">
                Send <i className="fa fa-paper-plane-o" aria-hidden="true" />
              </button>
            </div>
          </form>
        </div>
      </div>

    </>

  )
}

export default AllProfile