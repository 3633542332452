import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const SmsRegistrationForm = () => {
    const initialFormData = {
        fullname: '',
        marital_status: '',
        gender: '',
        dob: '',
        dob_time: '',
        birth_place: '',
        mother_tongue: '',
        rashi: '',
        star: '',
        paadham: '',

        caste: '',
        height: '',
        father: '',
        mother: '',
        brother: '',
        brother_married: '',
        sister: '',
        sister_married: '',
        birth_order: '',
        education: '',

        job_type: '',
        company_name: '',
        salary: '',
        work_place: '',
        sevaai_dhosam: '',
        sarpam_dhosam: '',
        raagu_dhosam: '',
        contact_person: '',
        mobile_1: '',
        mobile_2: '',

        whatsapp: '',
        email: '',
        living_place: '',
        pin_code: '',
        country: '',
        address: '',
        exceptations: '',
        profile_photo1: null,
        profile_photo2: null,


        horoscope: null,
        id_proof: null,
    };

    const initialFormErrors = {
        fullname: '',
        marital_status: '',
        gender: '',
        dob: '',
        dob_time: '',
        birth_place: '',
        mother_tongue: '',
        rashi: '',
        star: '',
        paadham: '',
        caste: '',
        height: '',
        color: '',
        father: '',
        mother: '',
        brother: '',
        brother_married: '',
        sister: '',
        sister_married: '',
        birth_order: '',
        education: '',
        job_type: '',
        company_name: '',
        salary: '',
        work_place: '',
        sevaai_dhosam: '',
        sarpam_dhosam: '',
        raagu_dhosam: '',
        contact_person: '',
        mobile_1: '',
        mobile_2: '',
        whatsapp: '',
        email: '',
        living_place: '',
        pin_code: '',
        address: '',

        country: '',
        exceptations: '',
        profile_photo1: '',
        profile_photo2: '',

        horoscope: '',
        id_proof: '',
    };

    const [formData, setFormData] = useState(initialFormData);
    const [formErrors, setFormErrors] = useState(initialFormErrors);

    const navigate = useNavigate();

    const validateField = (fieldName, value) => {
        let errorMessage = '';
        if (!value) {
            errorMessage = 'This field is required';
        }
        return errorMessage;
    };

    const validateImageFile = (file) => {
        let errorMessage = '';
        const validImageTypes = ['image/jpeg', 'image/png'];
        const maxSizeInMb = 2;

        if (!file) {
            errorMessage = 'This field is required';
        } else if (!validImageTypes.includes(file.type)) {
            errorMessage = 'The file should be in JPG, JPEG, or PNG format.';
        } else if (file.size > maxSizeInMb * 1024 * 1024) {
            errorMessage = `The file should be below ${maxSizeInMb} MB.`;
        }

        return errorMessage;
    };

    const validateHoroscopeFile = (file) => {
        let errorMessage = '';
        const maxSizeInMb = 2;

        if (!file) {
            errorMessage = 'This field is required';
        } else if (file.type !== 'application/pdf') {
            errorMessage = 'The Horoscope should be in PDF format.';
        } else if (file.size > maxSizeInMb * 1024 * 1024) {
            errorMessage = `The file should be below ${maxSizeInMb} MB.`;
        }

        return errorMessage;
    };

    const handleFileChange = (e) => {
        const { name, files } = e.target;
        const file = files[0];
        let errorMessage = '';

        if (name === 'horoscope') {
            errorMessage = validateHoroscopeFile(file);
        } else {
            errorMessage = validateImageFile(file);
        }

        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [name]: errorMessage,
        }));

        setFormData((prevData) => ({
            ...prevData,
            [name]: file,
        }));
    };

    const validateForm = () => {
        let isValid = true;
        const errors = { ...initialFormErrors };

        Object.keys(formData).forEach((fieldName) => {
            const value = formData[fieldName];
            let errorMessage = '';
            if (fieldName === 'horoscope') {
                errorMessage = validateHoroscopeFile(value);
            } else if (['profile_photo1', 'profile_photo2', 'id_proof'].includes(fieldName)) {
                errorMessage = validateImageFile(value);
            } else {
                errorMessage = validateField(fieldName, value);
            }
            errors[fieldName] = errorMessage;
            if (errorMessage) {
                isValid = false;
            }
        });

        setFormErrors(errors);
        return isValid;
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
        const errorMessage = validateField(name, value);
        setFormErrors({ ...formErrors, [name]: errorMessage });
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (validateForm()) {
            try {
                const formDataToSubmit = new FormData();
                Object.keys(formData).forEach((key) => {
                    formDataToSubmit.append(key, formData[key]);
                });

                // const response = await axios.post('http://localhost/cnnmandram/registeredUsers.php', formDataToSubmit, {
                const response = await axios.post('https://cnnmandram.com/cnnmandram/registeredUsers.php', formDataToSubmit, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                const responseData = response.data.data;

                if (responseData.status === 'valid') {
                    alert('Registration successful!');
                    setFormData(initialFormData);
                    navigate('/payment'); // Navigate to the payment component

                    // Display the unique ID
                    // alert('Your unique ID: ' + responseData.unique_id);
                } else {
                    alert('Registration failed: ' + responseData.error);
                }
            } catch (error) {
                alert('An error occurred during registration: ' + error.message);
            }
        } else {
            alert('Please fix the validation errors and try again.');
        }
    };

    return (


        <>

            <div className='container userRegisterationForm'>
                <div className="register-title">
                    <h3>REGISTRATION FORM</h3>

                </div>
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className=" col-md-6 col-sm-6 col-xs-12">



                            <div className="form-group">
                                <label className='text-start'>
                                    Full Name<span className="text-danger">*</span>:
                                </label>
                                <input
                                    type="text"

                                    autoComplete="on"
                                    name="fullname"
                                    value={formData.fullname}
                                    onChange={handleInputChange}

                                    className="form-control"
                                    placeholder="Enter Your Full Name"
                                />
                                {formErrors.fullname && (
                                    <span className="text-danger">{formErrors.fullname}</span>
                                )}
                            </div>
                        </div>
                        <div className="form-group col-md-6">
                            <label>
                                Marital Status<span className="text-danger">*</span>:
                            </label>
                            <select
                                name="marital_status"
                                value={formData.marital_status}
                                onChange={handleInputChange}
                                className="form-control"
                            >
                                <option value="">-- please select --</option>
                                <option value="single">Single</option>
                                <option value="remarriage">Remarriage</option>
                            </select>
                            {formErrors.marital_status && (
                                <span className="text-danger">{formErrors.marital_status}</span>
                            )}
                        </div>

                        <div className="form-group col-md-6">
                            <label>
                                Gender<span className="text-danger">*</span>:
                            </label>
                            <select
                                name="gender"
                                value={formData.gender}
                                onChange={handleInputChange}
                                className="form-control">
                                <option value="">-- please select --</option>
                                <option value="M">Male</option>
                                <option value="F">Female</option>
                            </select>
                            {formErrors.gender && (
                                <span className="text-danger">{formErrors.gender}</span>
                            )}
                        </div>

                        <div className="form-group col-md-6 col-sm-6 col-xs-12">
                            <label>
                                Date of Birth<span className="text-danger">*</span>:
                            </label>
                            <div className="input-group date form_date">
                                <input
                                    type="date"
                                    required=""
                                    autoComplete="on"
                                    id="datepicker"
                                    name="dob"
                                    value={formData.dob}
                                    onChange={handleInputChange}
                                    className="form-control"
                                    placeholder="Date of birth"
                                />

                            </div>
                            {formErrors.dob && (
                                <span className="text-danger">{formErrors.dob}</span>
                            )}
                        </div>
                        <div className="form-group col-md-6 col-sm-6 col-xs-12">
                            <label>
                                Birth Time<span className="text-danger">*</span>:
                            </label>
                            <div className="input-group bootstrap-timepicker timepicker">
                                <input
                                    type="text"
                                    required=""
                                    value={formData.dob_time}
                                    onChange={handleInputChange}

                                    autoComplete="on"
                                    name="dob_time"
                                    className="form-control input-small form_time"
                                    placeholder="Birth time"
                                />

                            </div>
                            {formErrors.dob_time && (
                                <span className="text-danger">{formErrors.dob_time}</span>
                            )}
                        </div>
                        <div className="form-group col-md-6 col-sm-6 col-xs-12">
                            <label>
                                Birth Place<span className="text-danger">*</span>:
                            </label>
                            <input
                                type="text"
                                required=""
                                autoComplete="on"
                                name="birth_place"
                                value={formData.birth_place}
                                onChange={handleInputChange}
                                className="form-control"
                                placeholder="Birth Place"
                            />
                            {formErrors.birth_place && (
                                <span className="text-danger">{formErrors.birth_place}</span>
                            )}

                        </div>
                        <div className="form-group col-md-6 col-sm-6 col-xs-12">
                            <label>
                                Mother Tongue<span className="text-danger">*</span>:
                            </label>
                            <select
                                type="text"
                                name="mother_tongue"
                                className="form-control"
                                required=""
                                value={formData.mother_tongue}
                                onChange={handleInputChange}
                            >
                                <option value="">-- please select --</option>
                                <option value='Tamil'>Tamil</option>
                                <option value='Telugu'>Telugu</option>
                                <option value='Malayalam'>Malayalam</option>
                                <option value='Kannada'>Kannada</option>
                                <option value='Hindi'>Hindi</option>
                                <option value='Others'>Others</option>
                            </select>
                            {formErrors.mother_tongue && (
                                <span className="text-danger">{formErrors.mother_tongue}</span>
                            )}
                        </div>
                        <div className="clearfix" />
                        <div className="form-group col-md-4 col-sm-6 col-xs-12">
                            <label>
                                Rashi<span className="text-danger">*</span>:
                            </label>
                            <select type="text" name="rashi" className="form-control" required="" value={formData.rashi}
                                onChange={handleInputChange}>


                                <option value="">-- please select --</option>
                                <option value="மேஷம்">மேஷம்</option>
                                <option value="ரிஷபம்">ரிஷபம்</option>
                                <option value="மிதுனம்">மிதுனம்</option>
                                <option value="கடகம்">கடகம்</option>
                                <option value="சிம்மம்">சிம்மம்</option>
                                <option value="கன்னி">கன்னி</option>
                                <option value="துலாம்">துலாம்</option>
                                <option value="விருச்சகம்">விருச்சகம்</option>
                                <option value="தனுசு">தனுசு</option>
                                <option value="மகரம்">மகரம்</option>
                                <option value="கும்பம்">கும்பம்</option>
                                <option value="மீனம்">மீனம்</option>




                            </select>
                            {formErrors.rashi && (
                                <span className="text-danger">{formErrors.rashi}</span>
                            )}
                        </div>
                        <div className="form-group col-md-4 col-sm-6 col-xs-12">
                            <label>
                                Natchathiram<span className="text-danger">*</span>:
                            </label>
                            <select type="text" name="star" className="form-control" required="" value={formData.star}
                                onChange={handleInputChange}>
                                <option value="">-- please select --</option>
                                <option value="அஸ்வினி">அஸ்வினி</option>
                                <option value="பரணி">பரணி</option>
                                <option value="கார்த்திகை">கார்த்திகை</option>
                                <option value="ரோகிணி">ரோகிணி</option>
                                <option value="மிருகசீரிடம்">மிருகசீரிடம்</option>
                                <option value="திருவாதிரை">திருவாதிரை</option>
                                <option value="புனர்பூசம்">புனர்பூசம்</option>
                                <option value="பூசம்">பூசம்</option>
                                <option value="ஆயில்யம்">ஆயில்யம்</option>
                                <option value="மகம்">மகம்</option>
                                <option value="பூரம்">பூரம்</option>
                                <option value="உத்திரம்">உத்திரம்</option>
                                <option value="அஸ்தம்">அஸ்தம்</option>
                                <option value="சித்திரை">சித்திரை</option>
                                <option value="சுவாதி">சுவாதி</option>
                                <option value="விசாகம்">விசாகம்</option>
                                <option value="அனுஷம்">அனுஷம்</option>
                                <option value="கேட்டை">கேட்டை</option>
                                <option value="மூலம்">மூலம்</option>
                                <option value="பூராடம்">பூராடம்</option>
                                <option value="உத்திராடம்">உத்திராடம்</option>
                                <option value="திருவோணம்">திருவோணம்</option>
                                <option value="அவிட்டம்">அவிட்டம்</option>
                                <option value="சதயம்">சதயம்</option>
                                <option value="பூரட்டாதி">பூரட்டாதி</option>
                                <option value="உத்திரட்டாதி">உத்திரட்டாதி</option>
                                <option value="ரேவதி">ரேவதி</option>

                            </select>
                            {formErrors.star && (
                                <span className="text-danger">{formErrors.star}</span>
                            )}
                        </div>



                        <div className="form-group col-md-4 col-sm-6 col-xs-12">
                            <label>
                                Paadham<span className="text-danger">*</span>:
                            </label>
                            <select type="text" name="paadham" className="form-control" required="" value={formData.paadham}
                                onChange={handleInputChange}>
                                <option value="">-- please select --</option>
                                <option value='1'>1</option>
                                <option value='2'>2</option>
                                <option value='3'>3</option>
                                <option value='4'>4</option>
                            </select>
                            {formErrors.paadham && (
                                <span className="text-danger">{formErrors.paadham}</span>
                            )}
                        </div>
                        <div className="form-group col-md-6 col-sm-6 col-xs-12">
                            <label>
                                Caste<span className="text-danger">*</span>:
                            </label>
                            <select
                                type="text"
                                name="caste"
                                className="form-control"
                                required=""
                                id="caste"
                                value={formData.caste}
                                onChange={handleInputChange}>
                                <option value="">-- please select --</option>
                                <option value="முதலியார்">முதலியார்</option>
                                <option value="ஆதி திராவிடர்">ஆதி திராவிடர்</option>
                                <option value="அருந்ததியர்">அருந்ததியர்</option>
                                <option value="போயர்">போயர்</option>
                                <option value="பிராமணர்">பிராமணர்</option>
                                <option value="செட்டியார்">செட்டியார்</option>
                                <option value="கவுண்டர்">கவுண்டர்</option>
                                <option value="கருணீகர்">கருணீகர்</option>
                                <option value="குலாலர்">குலாலர்</option>
                                <option value="குரும்பவார்">குரும்பவார்</option>
                                <option value="மருத்துவர்">மருத்துவர்</option>
                                <option value="மீனவர்">மீனவர்</option>
                                <option value="தேவர்">தேவர்</option>
                                <option value="14">நாடார்</option>
                                <option value="நாயக்கர்/வன்னியர்">நாயக்கர் / வன்னியர்</option>
                                <option value="நாயுடு">நாயுடு</option>
                                <option value="உடையார்/பார்க்கவ குலம்/மூப்பனார்">உடையார்/பார்க்கவ குலம்/மூப்பனார்</option>
                                <option value="ரெட்டி">ரெட்டி</option>
                                <option value="சேனைத் தலைவர்">சேனைத் தலைவர்</option>
                                <option value="சௌராட்டிரா">சௌராட்டிரா</option>
                                <option value="வண்ணார்">வண்ணார்</option>
                                <option value="வீரசைவம்/பண்டாரம்">வீரசைவம்/பண்டாரம்</option>
                                <option value="விஸ்வகர்மா">விஸ்வகர்மா</option>
                                <option value="ஒக்கலிகர்">ஒக்கலிகர்</option>
                                <option value="யாதவ்">யாதவ்</option>
                                <option value="கிராமணி">கிராமணி</option>
                                <option value="குறவர்">குறவர்</option>
                                <option value="சட்டாட ஸ்ரீவைஷ்ணவ">சட்டாட ஸ்ரீவைஷ்ணவ</option>
                                <option value="வள்ளுவன்">வள்ளுவன்</option>
                                <option value="படகர்">படகர்</option>
                                <option value="கிருஷ்ணவாகா">கிருஷ்ணவாகா</option>
                                <option value="கண்ட்லா/கனிகா">கண்ட்லா/கனிகா</option>
                                <option value="பர்வத ராஜகுலம்">பர்வத ராஜகுலம்</option>
                                <option value="பட்டணவர்">பட்டணவர்</option>
                                <option value="சாதுச் செட்டி">சாதுச் செட்டி</option>
                                <option value="தெலுங்குப்பட்டி">தெலுங்குப்பட்டி</option>
                                <option value="வடுகர்">வடுகர்</option>
                                <option value="தேசிகர்">தேசிகர்</option>
                                <option value="ஓதுவார்">ஓதுவார்</option>
                                <option value="கோரி/கோலி">கோரி/கோலி</option>
                                <option value="குருவா">குருவா</option>
                                <option value="ரோஹித்/சாமர்">ரோஹித்/சாமர்</option>
                                <option value="சாம்பவா">சாம்பவா</option>
                                <option value="பத்மசாலி">பத்மசாலி</option>
                                <option value="பட்டுசாலி">பட்டுசாலி</option>
                                <option value="பாஸ்வான்/தூசத்">பாஸ்வான்/தூசத்</option>
                                <option value="அஹிர்வார்">அஹிர்வார்</option>
                                <option value="பைரவா">பைரவா</option>
                                <option value="பலாய்">பலாய்</option>
                                <option value="தனக்">தனக்</option>
                                <option value="ஜுலாஹா">ஜுலாஹா</option>
                                <option value="கணக்கன் பாடண்ணா">கணக்கன் பாடண்ணா</option>
                                <option value="கந்தாரா">கந்தாரா</option>
                                <option value="காதிக்">காதிக்</option>
                                <option value="மன்னன்/வேலன்/வண்ணான்">மன்னன்/வேலன்/வண்ணான்</option>
                                <option value="மேக்வால்">மேக்வால்</option>
                                <option value="பாணன்">பாணன்</option>
                                <option value="பரவர்/பரதர்">பரவர்/பரதர்</option>
                                <option value="பவுண்ட்ரா">பவுண்ட்ரா</option>
                                <option value="புலையன்/செருமான்">புலையன்/செருமான்</option>
                                <option value="சாமாகார்">சாமாகார்</option>
                                <option value="சத்நமி">சத்நமி</option>
                                <option value="ஷில்ப்கர்">ஷில்ப்கர்</option>
                                <option value="சோங்கார்">சோங்கார்</option>
                                <option value="தாண்டன்">தாண்டன்</option>
                                <option value="அட்டவனை சாதியினர்(SC)">அட்டவனை சாதியினர்(SC)</option>
                                <option value="அட்டவனை பழங்குடியினர்(ST)">அட்டவனை பழங்குடியினர்(ST)</option>
                                <option value="ஈழவ">ஈழவ</option>
                                <option value="நாயர்">நாயர்</option>
                                <option value="ராஜ்பட்">ராஜ்பட்</option>
                                <option value="குறிப்பிட விரும்பவில்லை">குறிப்பிட விரும்பவில்லை</option>
                                <option value="அரோரா">அரோரா</option>
                                <option value="ராஜ்புட்">ராஜ்புட்</option>
                                <option value="மகாநியா">மகாநியா</option>
                                <option value="சிந்தி பாய்பந்த்">சிந்தி பாய்பந்த்</option>
                                <option value="காகோய்">காகோய்</option>
                                <option value="வெள்ளாளர்">வெள்ளாளர்</option>
                                <option value="தேவேந்திர குல வேளாளர்">தேவேந்திர குல வேளாளர்</option>
                                <option value="ஜய்ஷ்வால்">ஜய்ஷ்வால்</option>
                                <option value="கள்வர்">கள்வர்</option>
                                <option value="வைஷ்ணவ பணியாஷ்">வைஷ்ணவ பணியாஷ்</option>
                                <option value="முத்துராஜா/முத்தரையர்">முத்துராஜா/முத்தரையர்</option>
                                <option value="அகர்வால்">அகர்வால்</option>
                                <option value="கணக்குப்பிள்ளை">கணக்குப்பிள்ளை</option>
                                <option value="கம்மாளர்">கம்மாளர்</option>
                                <option value="மராட்டியர்">மராட்டியர்</option>
                                <option value="ஜங்கம்">ஜங்கம்</option>
                                <option value="வேடன்">வேடன்</option>
                                <option value="பழங்குடியினர்">பழங்குடியினர்</option>
                                <option value="ஜெயின்">ஜெயின்</option>
                                <option value="சத்யராஜூஸ்">சத்யராஜூஸ்</option>
                                <option value="குயவர்">குயவர்</option>
                                <option value="எஸ்.எஸ்.கே.சத்திரியர்">எஸ்.எஸ்.கே.சத்திரியர்</option>
                                <option value="ராஜுக்கள்">ராஜுக்கள்</option>
                                <option value="ராஜூஸ்">ராஜூஸ்</option>
                                <option value="ஈலுவா">ஈலுவா</option>
                                <option value="நாயர்">நாயர்</option>
                                <option value="படுகர்">படுகர்</option>
                                <option value="வீர கொடி வேளாளர்">வீர கொடி வேளாளர்</option>
                                <option value="சாலியர்">சாலியர்</option>
                                <option value="வல்லம்பர்">வல்லம்பர்</option>
                                <option value="பெஸ்டா">பெஸ்டா</option>
                                <option value="இசை வேளாளர்">இசை வேளாளர்</option>
                                <option value="கம்பர்">கம்பர்</option>
                                <option value="வீர சைவ லிங்காயத்">வீர சைவ லிங்காயத்</option>
                                <option value="யோகீஸ்வரர்">யோகீஸ்வரர்</option>
                                <option value="லோஹன">லோஹன</option>
                                <option value="ஆண்டிப்பண்டாரம்">ஆண்டிப்பண்டாரம்</option>
                                <option value="ஜெட்டியர்">ஜெட்டியர்</option>



                            </select>
                            {formErrors.caste && (
                                <span className="text-danger">{formErrors.caste}</span>
                            )}
                        </div>

                        <div className="form-group col-md-6 col-sm-6 col-xs-12">
                            <label>
                                Height<span className="text-danger">*</span>:
                            </label>
                            <input
                                type="text"
                                required=""
                                autoComplete="on"
                                name="height"
                                className="form-control"
                                placeholder="Height"
                                value={formData.height}
                                onChange={handleInputChange}
                            />
                            {formErrors.height && (
                                <span className="text-danger">{formErrors.height}</span>
                            )}
                        </div>





                        <h5>Family Details!</h5>
                        <div className="form-group col-md-6 col-sm-6 col-xs-12">
                            <label>
                                Father<span className="text-danger">*</span>:
                            </label>
                            <select type="text" name="father" className="form-control" required="" value={formData.father}
                                onChange={handleInputChange}>
                                <option value="">-- Please select --</option>
                                <option value="yes">Yes</option>
                                <option value="no">No (Late)</option>
                            </select>

                            {formErrors.father && (
                                <span className="text-danger">{formErrors.father}</span>
                            )}

                        </div>
                        <div className="form-group col-md-6 col-sm-6 col-xs-12">
                            <label>
                                Mother<span className="text-danger">*</span>:
                            </label>
                            <select type="text" name="mother" className="form-control" required="" value={formData.mother}
                                onChange={handleInputChange}>
                                <option value="">-- Please select --</option>
                                <option value="yes">Yes</option>
                                <option value="no">No (Late)</option>
                            </select>
                            {formErrors.mother && (
                                <span className="text-danger">{formErrors.mother}</span>
                            )}
                        </div>
                        <div className="form-group col-md-6 col-sm-6 col-xs-12">
                            <label>
                                Brothers<span className="text-danger">*</span>:
                            </label>
                            <select type="text" name="brother" className="form-control" required="" value={formData.brother}
                                onChange={handleInputChange}>
                                <option value="">-- Please select --</option>
                                <option value="-"> - </option>
                                <option value='1'>1</option>
                                <option value='2'>2</option>
                                <option value='3'>3</option>
                                <option value='4'>4</option>
                                <option value='5'>5</option>
                                <option value='6'>6</option>
                                <option value='7'>7</option>
                                <option value='8'>8</option>
                                <option value='9'>9</option>
                                <option value='10'>10</option>
                            </select>
                            {formErrors.brother && (
                                <span className="text-danger">{formErrors.brother}</span>
                            )}
                        </div>
                        <div className="form-group col-md-6 col-sm-6 col-xs-12">
                            <label>
                                Brother Married<span className="text-danger">*</span>:
                            </label>
                            <select
                                type="text"
                                name="brother_married"
                                className="form-control"
                                required=""

                                value={formData.brother_married}
                                onChange={handleInputChange}>

                                <option value="">-- Please select --</option>
                                <option value="-"> - </option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                            </select>
                            {formErrors.brother_married && (
                                <span className="text-danger">{formErrors.brother_married}</span>
                            )}
                        </div>
                        <div className="form-group col-md-6 col-sm-6 col-xs-12">
                            <label>
                                Sisters<span className="text-danger">*</span>:
                            </label>
                            <select type="text" name="sister" className="form-control" required="" value={formData.sister}
                                onChange={handleInputChange}>
                                <option value="">-- Please select --</option>
                                <option value="-"> - </option>
                                <option value='1'>1</option>
                                <option value='2'>2</option>
                                <option value='3'>3</option>
                                <option value='4'>4</option>
                                <option value='5'>5</option>
                                <option value='6'>6</option>
                                <option value='7'>7</option>
                                <option value='8'>8</option>
                                <option value='9'>9</option>
                                <option value='10'>10</option>
                            </select>
                            {formErrors.sister && (
                                <span className="text-danger">{formErrors.sister}</span>
                            )}
                        </div>
                        <div className="form-group col-md-6 col-sm-6 col-xs-12">
                            <label>
                                Sister Married<span className="text-danger">*</span>:
                            </label>
                            <select
                                type="text"
                                name="sister_married"
                                className="form-control"
                                required=""
                                value={formData.sister_married}
                                onChange={handleInputChange}>

                                <option value="">-- Please select --</option>
                                <option value="-"> - </option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                            </select>
                            {formErrors.sister_married && (
                                <span className="text-danger">{formErrors.sister_married}</span>
                            )}
                        </div>
                        <div className="form-group col-md-12 col-sm-12 col-xs-12">
                            <label>
                                Birth Order<span className="text-danger">*</span>:
                            </label>
                            <select
                                type="text"
                                name="birth_order"
                                className="form-control"
                                required=""
                                value={formData.birth_order}
                                onChange={handleInputChange}>

                                <option value="">-- Please select --</option>
                                <option value='1'>1</option>
                                <option value='2'>2</option>
                                <option value='3'>3</option>
                                <option value='4'>4</option>
                                <option value='5'>5</option>
                                <option value='6'>6</option>
                                <option value='7'>7</option>
                                <option value='8'>8</option>
                                <option value='9'>9</option>
                                <option value='10'>10</option>
                            </select>
                            {formErrors.birth_order && (
                                <span className="text-danger">{formErrors.birth_order}</span>
                            )}

                        </div>
                        <div className="clearfix" />
                        <h5>Education &amp; Job Details!</h5>
                        <div className="form-group col-md-12 col-sm-12 col-xs-12">
                            <label>
                                Education<span className="text-danger">*</span>:
                            </label>
                            <select
                                type="text"
                                name="education"
                                className="form-control"
                                required=""
                                value={formData.education}
                                onChange={handleInputChange}>

                                <option value="">-- Please select --</option>
                                <option value='PG/Engineers'>PG/Engineers</option>
                                <option value='UG/Diploma/SL'>UG/Diploma/SL</option>
                                <option value='Professionals'>Professionals</option>
                            </select>
                            {formErrors.education && (
                                <span className="text-danger">{formErrors.education}</span>
                            )}

                        </div>
                        <div className="form-group col-md-6 col-sm-12 col-xs-12">
                            <label>
                                Job Type<span className="text-danger">*</span>:
                            </label>
                            <select
                                type="text"
                                name="job_type"
                                className="form-control"
                                required=""
                                value={formData.job_type}
                                onChange={handleInputChange}>

                                <option value="">-- Please select --</option>
                                <option value='Employee'>Employee</option>
                                <option value='Unemployee'>Unemployee</option>
                                <option value='Business'>Business</option>
                                <option value='Studying'>Studying</option>
                                <option value='Professional'>Professional</option>
                                <option value='Tentative'>Tentative</option>
                            </select>
                            {formErrors.job_type && (
                                <span className="text-danger">{formErrors.job_type}</span>
                            )}
                        </div>
                        <div className="form-group col-md-6 col-sm-12 col-xs-12">
                            <label>
                                Company Name<span className="text-danger">*</span>:
                            </label>
                            <input
                                type="text"
                                required=""
                                autoComplete="on"
                                name="company_name"
                                className="form-control"
                                placeholder="Company Name"
                                value={formData.company_name}
                                onChange={handleInputChange} />
                            {formErrors.company_name && (
                                <span className="text-danger">{formErrors.company_name}</span>
                            )}
                        </div>
                        <div className="form-group col-md-6 col-sm-12 col-xs-12">
                            <label>
                                Monthly Salary<span className="text-danger">*</span>:
                            </label>
                            <input
                                type="text"
                                required=""
                                autoComplete="on"
                                name="salary"
                                className="form-control"
                                placeholder="Monthly Salary"
                                value={formData.salary}
                                onChange={handleInputChange}
                            />
                            {formErrors.salary && (
                                <span className="text-danger">{formErrors.salary}</span>
                            )}
                        </div>
                        <div className="form-group col-md-6 col-sm-12 col-xs-12">
                            <label>
                                Work Place<span className="text-danger">*</span>:
                            </label>
                            <input
                                type="text"
                                required=""
                                autoComplete="on"
                                name="work_place"
                                className="form-control"
                                placeholder="Work Place"
                                value={formData.work_place}
                                onChange={handleInputChange}
                            />
                            {formErrors.work_place && (
                                <span className="text-danger">{formErrors.work_place}</span>
                            )}
                        </div>
                        <div className="clearfix" />
                        <h5>Dosham!</h5>
                        <div className="form-group col-md-4 col-sm-12 col-xs-12">
                            <label>
                                Sevaai dosham<span className="text-danger">*</span>:
                            </label>
                            <select
                                type="text"
                                name="sevaai_dhosam"
                                className="form-control"
                                required=""
                                value={formData.sevaai_dhosam}
                                onChange={handleInputChange}
                            >
                                <option value="">-- please select --</option>
                                <option value="yes">Yes</option>
                                <option value="no">No</option>

                            </select>
                            {formErrors.sevaai_dhosam && (
                                <span className="text-danger">{formErrors.sevaai_dhosam}</span>
                            )}
                        </div>
                        <div className="form-group col-md-4 col-sm-12 col-xs-12">
                            <label>
                                Sarpa dosham<span className="text-danger">*</span>:
                            </label>
                            <select
                                type="text"
                                name="sarpam_dhosam"
                                className="form-control"
                                required=""
                                value={formData.sarpam_dhosam}
                                onChange={handleInputChange}
                            >
                                <option value="">-- please select --</option>
                                <option value="yes">Yes</option>
                                <option value="no">No </option>
                            </select>
                            {formErrors.sarpam_dhosam && (
                                <span className="text-danger">{formErrors.sarpam_dhosam}</span>
                            )}
                        </div>
                        <div className="form-group col-md-4 col-sm-12 col-xs-12">
                            <label>
                                Raagu dosham<span className="text-danger">*</span>:
                            </label>
                            <select
                                type="text"
                                name="raagu_dhosam"
                                className="form-control"
                                required=""
                                value={formData.raagu_dhosam}
                                onChange={handleInputChange}
                            >
                                <option value="">-- please select --</option>
                                <option value="yes">Yes</option>
                                <option value="no">No </option>
                            </select>
                            {formErrors.raagu_dhosam && (
                                <span className="text-danger">{formErrors.raagu_dhosam}</span>
                            )}
                        </div>
                        <div className="clearfix" />
                        <h5>Contact details!</h5>
                        <div className="form-group col-md-6 col-sm-6 col-xs-12">
                            <label>
                                Contact Person<span className="text-danger">*</span>:
                            </label>
                            <input
                                type="text"
                                required=""
                                autoComplete="on"
                                name="contact_person"
                                placeholder="Contact Person"
                                className="form-control contact_name"
                                value={formData.contact_person}
                                onChange={handleInputChange}
                            />
                            {formErrors.contact_person && (
                                <span className="text-danger">{formErrors.contact_person}</span>
                            )}
                        </div>

                        <div className="form-group col-md-6 col-sm-6 col-xs-12">
                            <label>
                                Mobile 1<span className="text-danger">*</span>:
                            </label>
                            <input
                                type="text"
                                required=""
                                autoComplete="on"
                                name="mobile_1"
                                placeholder="Mobile Number"
                                className="form-control mobile-1"
                                value={formData.mobile_1}
                                onChange={handleInputChange}
                            />
                            {formErrors.mobile_1 && (
                                <span className="text-danger">{formErrors.mobile_1}</span>
                            )}

                        </div>
                        <div className="form-group col-md-6 col-sm-6 col-xs-12">
                            <label>
                                Mobile 2<span className="text-danger">*</span>:
                            </label>

                            <input
                                type="text"
                                autoComplete="on"
                                name="mobile_2"
                                placeholder="Mobile Number"
                                className="form-control mobile-2"
                                value={formData.mobile_2}
                                onChange={handleInputChange}
                            />
                            {formErrors.mobile_2 && (
                                <span className="text-danger">{formErrors.mobile_2}</span>
                            )}
                        </div>
                        <div className="form-group col-md-6 col-sm-6 col-xs-12">
                            <label>WhatsApp:</label>
                            <input
                                type="text"
                                autoComplete="on"
                                name="whatsapp"
                                placeholder="Enter Whatsapp Number"
                                className="form-control whatsapp"
                                value={formData.whatsapp}
                                onChange={handleInputChange}
                            />
                            {formErrors.whatsapp && (
                                <span className="text-danger">{formErrors.whatsapp}</span>
                            )}
                        </div>
                        <div className="form-group col-md-6 col-sm-12 col-xs-12">
                            <label>
                                Email<span className="text-danger">*</span>:
                            </label>
                            <input
                                type="email"
                                required=""
                                autoComplete="on"
                                placeholder="Email ID"
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                className="form-control email"
                            />
                            {formErrors.email && (
                                <span className="text-danger">{formErrors.email}</span>
                            )}

                        </div>

                        <div className="form-group col-md-6 col-sm-12 col-xs-12">
                            <label>
                                Place<span className="text-danger">*</span>:
                            </label>
                            <input
                                type="text"
                                required=""
                                autoComplete="on"
                                placeholder="Enter Place"
                                name="living_place"
                                className="form-control"
                                value={formData.living_place}
                                onChange={handleInputChange}
                            />
                            {formErrors.living_place && (
                                <span className="text-danger">{formErrors.living_place}</span>
                            )}
                        </div>
                        <div className="form-group col-md-6 col-sm-12 col-xs-12">
                            <label>
                                PIN Code<span className="text-danger">*</span>:
                            </label>
                            <input
                                type="text"
                                required=""
                                autoComplete="on"
                                placeholder="Enter Pin Code"
                                name="pin_code"
                                className="form-control"
                                value={formData.pin_code}
                                onChange={handleInputChange}
                            />
                            {formErrors.pin_code && (
                                <span className="text-danger">{formErrors.pin_code}</span>
                            )}
                        </div>
                        <div className="form-group col-md-6 col-sm-12 col-xs-12">
                            <label>
                                Country<span className="text-danger">*</span>:
                            </label>
                            <select
                                type="text"
                                name="country"
                                className="form-control country_list"
                                required="" value={formData.country}
                                onChange={handleInputChange}
                            >
                                <option value="">-- please select --</option>
                                <option value={1}>India</option>

                            </select>
                            {formErrors.country && (
                                <span className="text-danger">{formErrors.country}</span>
                            )}
                        </div>
                        <div className="form-group col-md-6 col-sm-12 col-xs-12">
                            <label >
                                Address<span className="text-danger">*</span>:
                            </label>
                            <textarea
                                name="address"
                                placeholder="Address"
                                required=""
                                defaultValue={""}
                                value={formData.address}
                                onChange={handleInputChange} autoComplete='on'
                            />
                            {formErrors.address && (
                                <span className="text-danger">{formErrors.address}</span>
                            )}
                        </div>
                        <div className="form-group col-md-6 col-sm-12 col-xs-12">
                            <label >Expectation:</label>
                            <textarea
                                name="exceptations"
                                placeholder="Enter Partner Expectation"
                                defaultValue={""} value={formData.exceptations}
                                onChange={handleInputChange}
                            />
                            {formErrors.exceptations && (
                                <span className="text-danger">{formErrors.exceptations}</span>
                            )}
                        </div>
                        <div className="form-group col-md-12 col-sm-12 col-xs-12">
                            <div className="demo-wrap upload-demo">
                                <div className="col-md-6 col-sm-6 col-xs-12">
                                    <label>Profile photo-1: </label>
                                    <input
                                        type="file"
                                        id="upload"
                                        name="profile_photo1"
                                        onChange={handleFileChange}
                                    />
                                    {formErrors.profile_photo1 && (
                                        <span className="text-danger">{formErrors.profile_photo1}</span>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="form-group col-md-12 col-sm-12 col-xs-12">
                            <div className="demo-wrap upload-demo1">
                                <div className="col-md-6 col-sm-6 col-xs-12">
                                    <label>Profile photo-2: </label>
                                    <input
                                        type="file"
                                        id="upload1"
                                        name="profile_photo2"
                                        onChange={handleFileChange}
                                    />
                                    {formErrors.profile_photo2 && (
                                        <span className="text-danger">{formErrors.profile_photo2}</span>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="form-group col-md-12 col-sm-12 col-xs-12">
                            <label>Horoscope copy: </label>
                            <input
                                type="file"
                                name="horoscope"
                                onChange={handleFileChange}
                            />
                            {formErrors.horoscope && (
                                <span className="text-danger">{formErrors.horoscope}</span>
                            )}
                        </div>

                        <div className="form-group col-md-12 col-sm-12 col-xs-12">
                            <label>Your ID proof: </label>
                            <input
                                type="file"
                                name="id_proof"
                                onChange={handleFileChange}
                            />
                            {formErrors.id_proof && (
                                <span className="text-danger">{formErrors.id_proof}</span>
                            )}
                        </div>





                        <div className="form-group col-md-12 col-sm-12 col-xs-12">
                            <button type="submit" className="btn btn-primary">Submit</button>
                        </div>
                    </div>
                </form>
            </div>

        </>


    )
}

export default SmsRegistrationForm
