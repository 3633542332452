import React, { useState } from 'react';
import { Navbar, Container, Nav, Form, Modal, Button } from 'react-bootstrap';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { FaChevronDown } from "react-icons/fa";

const Header = ({ siteTitle }) => {
    const navigate = useNavigate();
    const [data, setData] = useState({ email: "", password: "" });
    const [user, setUser] = useState(null);
    const [showLogin, setShowLogin] = useState(false);
    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const [forgotPasswordData, setForgotPasswordData] = useState({ email: "", newPassword: "", confirmPassword: "" });

    const handleCloseLogin = () => setShowLogin(false);
    const handleShowLogin = () => setShowLogin(true);

    const handleCloseForgotPassword = () => setShowForgotPassword(false);
    const handleShowForgotPassword = () => setShowForgotPassword(true);

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const handleForgotPasswordChange = (e) => {
        setForgotPasswordData({ ...forgotPasswordData, [e.target.name]: e.target.value });
    };

    const submitForm = (e) => {
        e.preventDefault();
        const sendData = { email: data.email, password: data.password };

        // axios.post('http://localhost/cnnmandram/login.php', sendData, {
        axios.post('https://cnnmandram.com/cnnmandram/login.php', sendData, {
            headers: { 'Content-Type': 'application/json' }
        })
            .then((result) => {
                if (result.data.status === 'inactive') {
                    alert(result.data.message);
                } else if (result.data.status === 'invalid') {
                    alert('Invalid Email or Password');
                } else if (result.data.status === 'success') {
                    setUser(result.data.user);
                    handleCloseLogin();
                    navigate('/');
                } else {
                    alert('Unexpected response from server');
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    alert('Your account is not active. Please contact support.');
                } else {
                    console.error('There was an error!', error);
                    alert('Error submitting form. Please try again later.');
                }
            });
    };

    const submitForgotPasswordForm = (e) => {
        e.preventDefault();

        if (forgotPasswordData.newPassword !== forgotPasswordData.confirmPassword) {
            alert("Passwords do not match");
            return;
        }

        const sendData = {
            email: forgotPasswordData.email,
            newPassword: forgotPasswordData.newPassword
        };

        axios.post('https://cnnmandram.com/cnnmandram/reset_password.php', sendData, {
            // axios.post('http://localhost/cnnmandram/reset_password.php', sendData, {
            headers: { 'Content-Type': 'application/json' }
        })
            .then((result) => {
                if (result.data.status === 'invalid') {
                    alert('Invalid Email');
                } else if (result.data.status === 'inactive') {
                    alert('Your account is not active');
                } else if (result.data.status === 'success') {
                    handleCloseForgotPassword();
                    alert('Password reset successfully. You can now log in with your new password.');
                } else {
                    alert('Unexpected response from server');
                }
            })
            .catch((error) => {
                console.error('There was an error!', error);
                alert('Error submitting form. Please try again later.');
            });
    };

    const handleLogout = () => {
        setUser(null);
        navigate('/');
    };

    const handleProtectedNavClick = (e, path) => {
        if (!user) {
            e.preventDefault();
            navigate('/SignUp');
        } else {
            navigate(path);
        }
    };

    return (
        <>
            <div className="head-top">
                <div className="container">
                    <div className="row">
                        <div className="topBar">
                            <ul className='topBarList mb-0'>
                                <li><i className="fa fa-search" aria-hidden="true" /></li>
                                <li><Link to='/About'>About</Link></li>
                                <li><Link to="/Faq">FAQ</Link></li>
                                <li><Link to="/Contact">Contact</Link></li>
                            </ul>
                            <ul className='topRightList mb-0'>
                                <li><a href="tel:+91 044 2432 2858"><i className="fa fa-phone" aria-hidden="true" />&nbsp;+91 044 2432 2858</a></li>
                                <li><a href="mailto:cnnm1985@gmail.com"><i className="fa fa-envelope-o" aria-hidden="true" />&nbsp; cnnm1985@gmail.com</a></li>
                                <li><i className="fa fa-facebook" aria-hidden="true" /></li>
                                <li><i className="fa fa-twitter" aria-hidden="true" /></li>
                                <li><i className="fa fa-whatsapp" aria-hidden="true" /></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="hom-top">
                <Navbar expand="md" className="bg-body-tertiary">
                    <Container fluid>
                        <Navbar.Brand href="#">
                            <Link to="/">
                                <img src="./images/cnnmLogo.png" alt="Logo" loading="lazy" className="ic-logo img-fluid" style={{ maxWidth: '90px' }} />
                                <br />
                            </Link>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="navbarScroll" />
                        <Navbar.Collapse id="navbarScroll">
                            <Nav className="me-auto my-2 my-lg-0" navbarScroll>
                                <Nav.Link className="smenu-pare">
                                    <span>
                                        <NavLink to="/" className={({ isActive }) => (isActive ? "active-link" : "")}>HOME</NavLink>
                                    </span>
                                </Nav.Link>
                                <Nav.Link className="smenu-pare">
                                    <span>
                                        <span className="aboutMemuHover"><NavLink to="/About" className={({ isActive }) => (isActive ? "active-link" : "")}>
                                            About <span className='aboutIcon'><FaChevronDown /></span>
                                        </NavLink></span>
                                        <ul className='aboutInnerList'>
                                            <li><Link to='/History'>History</Link></li>
                                            <li><Link to='/Memories'>Memories</Link></li>
                                            <li><Link to='/Motives'>Motives</Link></li>
                                        </ul>
                                    </span>
                                </Nav.Link>
                                <Nav.Link className="smenu-pare">
                                    <span className="smeu">
                                        <NavLink to="/AllProfile" className={({ isActive }) => (isActive ? "active-link" : "")} onClick={(e) => handleProtectedNavClick(e, '/AllProfile')}>All profiles</NavLink>
                                    </span>
                                </Nav.Link>
                                <Nav.Link className="smenu-pare">
                                    <span className="smeu">
                                        <NavLink to="/Plans">Plans</NavLink>
                                    </span>
                                </Nav.Link>
                                <Nav.Link className="smenu-pare">
                                    <span className="smeu">
                                        <NavLink to="/SignUp" className={({ isActive }) => (isActive ? "active-link" : "")}>Register</NavLink>
                                    </span>
                                </Nav.Link>
                            </Nav>

                            {user ? (
                                <div className="d-flex align-items-center ms-4">

                                    <div className='row'>
                                        <div className="col">

                                            <img src={`https://cnnmandram.com/cnnmandram/${user.profile_photo}`} alt="Profile" className="rounded-circle me-2" style={{ width: '40px', height: '40px' }} />
                                            {/* <img src={`https://localhost/cnnmandram/${user.profile_photo}`} alt="Profile" className="rounded-circle me-2" style={{ width: '40px', height: '40px' }} /> */}
                                            <br />   <small className="me-3">{user.full_name}

                                                : {user.unique_id}</small>
                                        </div>
                                    </div>   <button type="button" className="btn btn-outline-dark btn-login shadow-none me-lg-3" onClick={handleLogout}>
                                        Logout
                                    </button>
                                </div>
                            ) : (
                                <>
                                    <button type="button" className="btn btn-outline-dark btn-login shadow-none me-lg-3" onClick={handleShowLogin}>
                                        Login
                                    </button>

                                    <Modal show={showLogin} onHide={handleCloseLogin}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>User Login</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <Form onSubmit={submitForm}>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>Email address</Form.Label>
                                                    <Form.Control type="email" placeholder="Enter email" name="email" value={data.email} onChange={handleChange} required />
                                                </Form.Group>
                                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                                    <Form.Label>Password</Form.Label>
                                                    <Form.Control type="password" placeholder="Password" name="password" value={data.password} onChange={handleChange} required />
                                                </Form.Group>
                                                <div className="row">
                                                    <div className="col-4">
                                                        <Button variant="primary" type="submit">
                                                            Login
                                                        </Button>
                                                    </div>
                                                    <div className="col-8 text-end">
                                                        <p className="mt-2">
                                                            <span onClick={() => { handleCloseLogin(); handleShowForgotPassword(); }} style={{ cursor: 'pointer', color: 'blue' }}>
                                                                Forget Password?
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </Form>
                                        </Modal.Body>
                                    </Modal>

                                    <Modal show={showForgotPassword} onHide={handleCloseForgotPassword}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Forgot Password</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <Form onSubmit={submitForgotPasswordForm}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Email</Form.Label>
                                                    <Form.Control type="email" name="email" required onChange={handleForgotPasswordChange} value={forgotPasswordData.email} autoComplete="off" />
                                                </Form.Group>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>New Password</Form.Label>
                                                    <Form.Control type="password" name="newPassword" required onChange={handleForgotPasswordChange} value={forgotPasswordData.newPassword} autoComplete="off" />
                                                </Form.Group>
                                                <Form.Group className="mb-4">
                                                    <Form.Label>Confirm Password</Form.Label>
                                                    <Form.Control type="password" name="confirmPassword" required onChange={handleForgotPasswordChange} value={forgotPasswordData.confirmPassword} autoComplete="off" />
                                                </Form.Group>
                                                <Button type="submit" variant="dark">Reset Password</Button>
                                            </Form>
                                        </Modal.Body>
                                    </Modal>
                                </>
                            )}
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>
        </>
    );
};

export default Header;
