import React, { useState } from 'react';

const RegistrationForm = () => {
    const [data, setData] = useState({
        firstName: '',
        lastName: '',
        role: '',
        religion: '',
        caste: '',
        clause: '',
        marriedLife: '',
        childrenMale: false,
        childrenFemale: false,
        dob: '',
        age: '',
        color: '',
        height: '',
        education: '',
        occupation: '',
        workplace: '',
        salary: '',
        fatherName: '',
        motherName: '',
        siblingsMale: false,
        siblingsFemale: false,
        origin: '',
        location: '',
        familyGod: '',
        sequence: '',
        assets: '',
        star: '',
        zodiac: '',
        ascendant: '',
        address: '',
        phoneNumber: '',
        agree: false,
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setData({
            ...data,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const validate = () => {
        const newErrors = {};
        if (!data.firstName) newErrors.firstName = 'First name is required';
        if (!data.lastName) newErrors.lastName = 'Last name is required';
        if (!data.role) newErrors.role = 'Role is required';
        if (!data.religion) newErrors.religion = 'Religion is required';
        if (!data.caste) newErrors.caste = 'Caste is required';
        if (!data.clause) newErrors.clause = 'Clause is required';
        if (!data.marriedLife) newErrors.marriedLife = 'Married Life is required';
        if (!data.dob) newErrors.dob = 'Date of Birth is required';
        if (!data.age) newErrors.age = 'Age is required';
        if (!data.color) newErrors.color = 'Color is required';
        if (!data.height) newErrors.height = 'Height is required';
        if (!data.education) newErrors.education = 'Education is required';
        if (!data.occupation) newErrors.occupation = 'Occupation is required';
        if (!data.workplace) newErrors.workplace = 'Workplace is required';
        if (!data.salary) newErrors.salary = 'Salary is required';
        if (!data.fatherName) newErrors.fatherName = 'Father Name is required';
        if (!data.motherName) newErrors.motherName = 'Mother Name is required';
        if (!data.origin) newErrors.origin = 'Origin is required';
        if (!data.location) newErrors.location = 'Location is required';
        if (!data.familyGod) newErrors.familyGod = 'Family God is required';
        if (!data.sequence) newErrors.sequence = 'Sequence is required';
        if (!data.assets) newErrors.assets = 'Assets are required';
        if (!data.star) newErrors.star = 'Star is required';
        if (!data.zodiac) newErrors.zodiac = 'Zodiac is required';
        if (!data.ascendant) newErrors.ascendant = 'Ascendant is required';
        if (!data.address) newErrors.address = 'Address is required';
        if (!data.phoneNumber) newErrors.phoneNumber = 'Phone Number is required';
        if (!data.agree) newErrors.agree = 'You must agree to the terms';

        return newErrors;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const validationErrors = validate();
        setErrors(validationErrors);

        if (Object.keys(validationErrors).length === 0) {
            console.log('Form submitted successfully', data);
        }
    };

    return (
        <div>
            <div className="container mt-5">
                <form className="regForm" onSubmit={handleSubmit}>
                    <div className="row mb-3">
                        <div className="col-md-6">
                            <label htmlFor="firstName" className="form-label">First Name</label>
                            <input
                                type="text"
                                className="form-control"
                                id="firstName"
                                name="firstName"
                                placeholder="Enter your first name"
                                value={data.firstName}
                                onChange={handleChange}
                            />
                            {errors.firstName && <small className="text-danger">{errors.firstName}</small>}
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="lastName" className="form-label">Last Name</label>
                            <input
                                type="text"
                                className="form-control"
                                id="lastName"
                                name="lastName"
                                placeholder="Enter your last name"
                                value={data.lastName}
                                onChange={handleChange}
                            />
                            {errors.lastName && <small className="text-danger">{errors.lastName}</small>}
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-md-6">
                            <label htmlFor="role" className="form-label">I am</label>
                            <select className="form-select" id="role" name="role" value={data.role} onChange={handleChange}>
                                <option value="">Select</option>
                                <option value="Groom">Groom</option>
                                <option value="Bride">Bride</option>
                            </select>
                            {errors.role && <small className="text-danger">{errors.role}</small>}
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="religion" className="form-label">Religion</label>
                            <select className="form-select" id="religion" name="religion" value={data.religion} onChange={handleChange}>
                                <option value="">Select</option>
                                <option value="Hindu">Hindu</option>
                                {/* Add other religions as needed */}
                            </select>
                            {errors.religion && <small className="text-danger">{errors.religion}</small>}
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="caste" className="form-label">Caste</label>
                            <select className="form-select" id="caste" name="caste" value={data.caste} onChange={handleChange}>
                                <option value="">Select</option>
                                <option value="Pillai">Pillai</option>
                                {/* Add other castes as needed */}
                            </select>
                            {errors.caste && <small className="text-danger">{errors.caste}</small>}
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="clause" className="form-label">Clause</label>
                            <select className="form-select" id="clause" name="clause" value={data.clause} onChange={handleChange}>
                                <option value="">Select</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                            </select>
                            {errors.clause && <small className="text-danger">{errors.clause}</small>}
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="marriedLife" className="form-label">Married Life</label>
                            <select className="form-select" id="marriedLife" name="marriedLife" value={data.marriedLife} onChange={handleChange}>
                                <option value="">Select</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                            </select>
                            {errors.marriedLife && <small className="text-danger">{errors.marriedLife}</small>}
                        </div>
                        <div className="col-md-6">
                            <label className="form-label">Children if married</label><br />
                            <input
                                className="form-check-input"
                                type="checkbox"
                                name="childrenMale"
                                onChange={handleChange}
                                checked={data.childrenMale}
                            /> Male
                            <input
                                className="form-check-input ms-2"
                                type="checkbox"
                                name="childrenFemale"
                                onChange={handleChange}
                                checked={data.childrenFemale}
                            /> Female
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="dob" className="form-label">Date of Birth</label>
                            <input
                                type="date"
                                className="form-control"
                                id="dob"
                                name="dob"
                                value={data.dob}
                                onChange={handleChange}
                            />
                            {errors.dob && <small className="text-danger">{errors.dob}</small>}
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="age" className="form-label">Age</label>
                            <input
                                type="text"
                                className="form-control"
                                id="age"
                                name="age"
                                value={data.age}
                                onChange={handleChange}
                            />
                            {errors.age && <small className="text-danger">{errors.age}</small>}
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="color" className="form-label">Color</label>
                            <input
                                type="text"
                                className="form-control"
                                id="color"
                                name="color"
                                value={data.color}
                                onChange={handleChange}
                            />
                            {errors.color && <small className="text-danger">{errors.color}</small>}
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="height" className="form-label">Height</label>
                            <input
                                type="text"
                                className="form-control"
                                id="height"
                                name="height"
                                value={data.height}
                                onChange={handleChange}
                            />
                            {errors.height && <small className="text-danger">{errors.height}</small>}
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="education" className="form-label">Education</label>
                            <input
                                type="text"
                                className="form-control"
                                id="education"
                                name="education"
                                value={data.education}
                                onChange={handleChange}
                            />
                            {errors.education && <small className="text-danger">{errors.education}</small>}
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="occupation" className="form-label">Occupation</label>
                            <input
                                type="text"
                                className="form-control"
                                id="occupation"
                                name="occupation"
                                value={data.occupation}
                                onChange={handleChange}
                            />
                            {errors.occupation && <small className="text-danger">{errors.occupation}</small>}
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="workplace" className="form-label">Workplace</label>
                            <input
                                type="text"
                                className="form-control"
                                id="workplace"
                                name="workplace"
                                value={data.workplace}
                                onChange={handleChange}
                            />
                            {errors.workplace && <small className="text-danger">{errors.workplace}</small>}
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="salary" className="form-label">Salary</label>
                            <input
                                type="text"
                                className="form-control"
                                id="salary"
                                name="salary"
                                value={data.salary}
                                onChange={handleChange}
                            />
                            {errors.salary && <small className="text-danger">{errors.salary}</small>}
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="fatherName" className="form-label">Father's Name</label>
                            <input
                                type="text"
                                className="form-control"
                                id="fatherName"
                                name="fatherName"
                                value={data.fatherName}
                                onChange={handleChange}
                            />
                            {errors.fatherName && <small className="text-danger">{errors.fatherName}</small>}
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="motherName" className="form-label">Mother's Name</label>
                            <input
                                type="text"
                                className="form-control"
                                id="motherName"
                                name="motherName"
                                value={data.motherName}
                                onChange={handleChange}
                            />
                            {errors.motherName && <small className="text-danger">{errors.motherName}</small>}
                        </div>
                        <div className="col-md-6">
                            <label className="form-label">Siblings</label><br />
                            <input
                                className="form-check-input"
                                type="checkbox"
                                name="siblingsMale"
                                onChange={handleChange}
                                checked={data.siblingsMale}
                            /> Male
                            <input
                                className="form-check-input ms-2"
                                type="checkbox"
                                name="siblingsFemale"
                                onChange={handleChange}
                                checked={data.siblingsFemale}
                            /> Female
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="origin" className="form-label">Origin</label>
                            <input
                                type="text"
                                className="form-control"
                                id="origin"
                                name="origin"
                                value={data.origin}
                                onChange={handleChange}
                            />
                            {errors.origin && <small className="text-danger">{errors.origin}</small>}
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="location" className="form-label">Current Location</label>
                            <input
                                type="text"
                                className="form-control"
                                id="location"
                                name="location"
                                value={data.location}
                                onChange={handleChange}
                            />
                            {errors.location && <small className="text-danger">{errors.location}</small>}
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="familyGod" className="form-label">Family God</label>
                            <input
                                type="text"
                                className="form-control"
                                id="familyGod"
                                name="familyGod"
                                value={data.familyGod}
                                onChange={handleChange}
                            />
                            {errors.familyGod && <small className="text-danger">{errors.familyGod}</small>}
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="sequence" className="form-label">Sequence</label>
                            <input
                                type="text"
                                className="form-control"
                                id="sequence"
                                name="sequence"
                                value={data.sequence}
                                onChange={handleChange}
                            />
                            {errors.sequence && <small className="text-danger">{errors.sequence}</small>}
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="assets" className="form-label">Assets</label>
                            <input
                                type="text"
                                className="form-control"
                                id="assets"
                                name="assets"
                                value={data.assets}
                                onChange={handleChange}
                            />
                            {errors.assets && <small className="text-danger">{errors.assets}</small>}
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="star" className="form-label">Star</label>
                            <input
                                type="text"
                                className="form-control"
                                id="star"
                                name="star"
                                value={data.star}
                                onChange={handleChange}
                            />
                            {errors.star && <small className="text-danger">{errors.star}</small>}
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="zodiac" className="form-label">Zodiac</label>
                            <input
                                type="text"
                                className="form-control"
                                id="zodiac"
                                name="zodiac"
                                value={data.zodiac}
                                onChange={handleChange}
                            />
                            {errors.zodiac && <small className="text-danger">{errors.zodiac}</small>}
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="ascendant" className="form-label">Ascendant</label>
                            <input
                                type="text"
                                className="form-control"
                                id="ascendant"
                                name="ascendant"
                                value={data.ascendant}
                                onChange={handleChange}
                            />
                            {errors.ascendant && <small className="text-danger">{errors.ascendant}</small>}
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="address" className="form-label">Address</label>
                            <input
                                type="text"
                                className="form-control"
                                id="address"
                                name="address"
                                value={data.address}
                                onChange={handleChange}
                            />
                            {errors.address && <small className="text-danger">{errors.address}</small>}
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="phoneNumber" className="form-label">Phone Number</label>
                            <input
                                type="text"
                                className="form-control"
                                id="phoneNumber"
                                name="phoneNumber"
                                value={data.phoneNumber}
                                onChange={handleChange}
                            />
                            {errors.phoneNumber && <small className="text-danger">{errors.phoneNumber}</small>}
                        </div>
                        <div className="col-md-12">
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="agree"
                                    name="agree"
                                    checked={data.agree}
                                    onChange={handleChange}
                                />
                                <label className="form-check-label" htmlFor="agree">
                                    I agree to the terms and conditions
                                </label>
                                {errors.agree && <small className="text-danger">{errors.agree}</small>}
                            </div>
                        </div>
                    </div>
                    <button type="submit" className="btn btn-primary">Submit</button>
                </form>
            </div>
        </div>
    );
};

export default RegistrationForm;

