import React, { useEffect, useState } from 'react';
import axios from 'axios';

const RegisteredUsers = () => {
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [filterGender, setFilterGender] = useState('');
    const [filterAgeRange, setFilterAgeRange] = useState('');
    const [filterDistrict, setFilterDistrict] = useState('');

    useEffect(() => {
        fetchUsers();
    }, []);

    useEffect(() => {
        applyFilters();
    }, [filterGender, filterAgeRange, filterDistrict, users]);

    const fetchUsers = async () => {
        try {
            const response = await axios.get('https://cnnmandram.com/cnnmandram/fetchRegisteredUsers.php');
            // const response = await axios.get('http://localhost/cnnmandram/fetchRegisteredUsers.php');
            setUsers(response.data);
            setFilteredUsers(response.data);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };


    const applyFilters = () => {
        let filteredData = users;

        if (filterGender !== '') {
            filteredData = filteredData.filter(user => user.gender === filterGender);
        }

        // Function to calculate age from date of birth
        const calculateAge = (dateOfBirth) => {
            const today = new Date();
            const birthDate = new Date(dateOfBirth);
            let age = today.getFullYear() - birthDate.getFullYear();
            const monthDiff = today.getMonth() - birthDate.getMonth();

            if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }

            return age;
        };

        // Inside applyFilters function
        if (filterAgeRange !== '') {
            const [minAge, maxAge] = filterAgeRange.split('-').map(Number);

            filteredData = filteredData.filter(user => {
                const userAge = calculateAge(user.date_of_birth); // Replace with your actual age calculation logic
                return userAge >= minAge && userAge <= maxAge;
            });
        }
        if (filterDistrict !== '') {
            filteredData = filteredData.filter(user => user.living_place === filterDistrict);
        }

        setFilteredUsers(filteredData);
    };

    const handleGenderChange = (e) => {
        setFilterGender(e.target.value);
    };





    const handleAgeRangeChange = (e) => {
        setFilterAgeRange(e.target.value);
    };

    const handleDistrictChange = (e) => {
        setFilterDistrict(e.target.value);
    };

    return (
        <div className="container mt-5">
            <h2 className="mb-4">Registered Users</h2>
            <div className="filters">
                <div className="form-group">
                    <label htmlFor="genderFilter" className="form-label">Filter by Gender:</label>
                    <select
                        id="genderFilter"
                        className="form-control"
                        value={filterGender}
                        onChange={handleGenderChange}
                    >
                        <option value="">-- All --</option>
                        <option value="M">Male</option>
                        <option value="F">Female</option>
                    </select>
                </div>

                {/* Age range filter (if needed) */}
                {/* Replace with your age range filter UI */}
                <div className="form-group">
                    <label htmlFor="ageRangeFilter" className="form-label">Filter by Age Range:</label>
                    <select
                        id="ageRangeFilter"
                        className="form-control"
                        value={filterAgeRange}
                        onChange={handleAgeRangeChange}
                    >
                        <option value="">-- All --</option>
                        <option value="18-30">18 to 30</option>
                        <option value="31-40">31 to 40</option>

                    </select>
                </div>

                <div className="form-group">
                    <label htmlFor="livingPlaceFilter" className="form-label">Filter by Living Place:</label>
                    <select
                        id="livingPlaceFilter"
                        className="form-control"
                        value={filterDistrict}
                        onChange={handleDistrictChange}
                    >
                        <option value="">-- All --</option>
                        <option value="Ariyalur">Ariyalur</option>
                        <option value="Chengalpattu">Chengalpattu</option>
                        <option value="Chennai">Chennai</option>
                        <option value="Coimbatore">Coimbatore</option>
                        {/* Add more districts as needed */}
                    </select>
                </div>
            </div>

            <div className="table-responsive">
                <table className="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Full Name</th>
                            <th>Marital Status</th>
                            <th>Gender</th>
                            {/* Include other headers as needed */}
                            <th>Living Place</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredUsers.map((user) => (
                            <tr key={user.id}>
                                <td>{user.id}</td>
                                <td>{user.fullname}</td>
                                <td>{user.marital_status}</td>
                                <td>{user.gender}</td>
                                {/* Include other data cells as needed */}
                                <td>{user.living_place}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default RegisteredUsers;


